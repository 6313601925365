<template>
	<el-row :gutter="160">
		<el-col :span="2">
			<el-button type="primary" icon="el-icon-upload2" @click="onClickUpload">上传代码包</el-button>
		</el-col>
		<el-col :span="24">
			<el-table ref="multipleTable" :data="pkgList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
			:cell-style="{padding:8+'px'}" class="serverlist">
				<el-table-column prop="id" label="id" width="60"> </el-table-column>
				<el-table-column prop="name" label="代码包文件名"></el-table-column>
				<el-table-column prop="tag" label="标记"></el-table-column>
				<el-table-column prop="create_time" label="上传时间"></el-table-column>
			</el-table>
		</el-col>
		<!-- ========上传弹框BEGIN====== -->
		<el-dialog title="上传文件" :visible.sync="dialogUpload" width="480px" :close-on-click-modal="false" center class="dlgupload">
			<el-form>
				<el-form-item>
					<el-upload class="upload-demo" drag 
                    ref="upload"
                    :action="uploadAction"
                    :limit="1" 
					:file-list="uploadFileList"
                    :on-change="onUploadPreview"
                    :on-success="onUploadSuccess"
                    :on-remove="onUploadRemove"
                    :on-error="onUploadRemove"
                    :auto-upload="false">
						<i class="el-icon-upload"></i>
						<div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
						<div class="el-upload__tip" slot="tip">只能上传zip文件，且不超过16MB</div>
					</el-upload>
				</el-form-item>
				<el-form-item>
					<el-input v-model="pkgtag" placeholder="请输入标签, 方便识别"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-check" @click="onCommitUploadPkg" :disabled="noneUploaded">确 定</el-button>
				<el-button icon="el-icon-close" @click="dialogUpload = false">取 消</el-button>
			</div>
		</el-dialog>
		<!-- ========上传弹框END====== -->
		<!-- ========设置pkg弹框END====== -->
        <el-dialog title="提示" :visible.sync="dialogLoading" width="640px" :close-on-click-modal="false" :show-close="false">
          <span>正在检查zip文件...</span>
        </el-dialog>
	</el-row>
</template>

<script>
	import {
		mapGetters
	} from 'vuex'
    import admzip from 'adm-zip'
    import axios from 'axios'
    import tool from "../../tool.js"

	export default {
		props: ['pkgtype'],
		data() {
			return {
                dialogLoading: false,
                checkedPkgName: false,
				dialogUpload: false,
				noneUploaded: true, // 标记未上传(按钮状态)
                pkgCenterApi: null,
                uploadAction: this.$store.state.game.pkgurl + '/sygame_upload_client',
				uploadFileList: [],
				pkgList: [],
				pkgtag: '',
                tableHeight: document.documentElement.clientHeight-136,
			}
		},
		created: function() {
            // 打包中心httl
            this.pkgCenterApi = axios.create({
                baseURL: this.$store.state.game.pkgurl,
                timeout: 15000
            });  
			this.refreshPkgList()
            console.log("OSS=>", this.$store.state.game.ossbucket, this.$store.state.game.osspkgdir)
		},
		computed: {
			...mapGetters(
				['gameHttp']
			),
		},
		methods: {
            // 发送请求到打包中心
            pkgCenterHttp (cmd, data) {
                let rqs = JSON.parse(JSON.stringify(data));
                rqs.cmd = cmd;
                return this.pkgCenterApi({
                    method: 'post',
                    url: '/sygame',
                    data: rqs
                });
            },
			// 提交上传文件
			onCommitUploadPkg() {
                console.log("uploadFileList=>", this.uploadFileList);
                let uploadFileName = this.uploadFileList[0].name;
				this.pkgCenterHttp(2002, {
					name: uploadFileName,
					type: 'pkg', // 暂时不用区分
					tag: this.pkgtag,
                    user: this.$store.state.username
				}).then((response) => {
					if (0 == response.data.errcode) {
						this.$message.success(response.data.errmsg)
						this.dialogUpload = false
						this.refreshPkgList()
					} else {
						this.$message.error(response.data.errmsg)
					}
                
				})
			},
			// 刷新代码/配置包列表
			refreshPkgList() {
				this.pkgCenterHttp(2001, {
					type: this.pkgtype
				}).then((response) => {
					this.pkgList = response.data.list
				})
			},

			// 弹出上传文件
			onClickUpload() {
                this.checkedPkgName = false;
				this.dialogUpload = true;
				this.noneUploaded = true;
				this.uploadFileList = [];
				this.pkgtag = '';
			},
            // 检查是否重名
            checkExistName(name) {
				this.pkgCenterHttp(2002, {
					name: name,
					check: 1
				}).then((response) => {
                    this.dialogLoading = false;
					if (0 == response.data.errcode) {
						this.$refs.upload.submit();
					} else {
                        this.pkgtag = '';
                        this.uploadFileList = [];
                        this.checkedPkgName = false;
						this.$message.error(response.data.errmsg);
					}
				})
            },
            // 获取根目录名
            getRootDirName(entries) {
                for (let i=0; i<entries.length; i++) {
                    let name = entries[i].entryName;
                    if (1==tool.charnumInsideString(name, "/") && "/"==name.charAt(name.length-1) ) {
                        return name;
                    }
                }
                return "";
            },
            // 上传预览
            onUploadPreview(file) {
                console.log("onUploadPreview=>", file.name, file)
                if (this.checkedPkgName) {
                    return;
                }
                this.dialogLoading = true;
                this.checkedPkgName = true;
                // 用FileReader来读取
                var reader = new FileReader();
                // 重写FileReader上的readAsBinaryString方法
                FileReader.prototype.readAsBinaryString = (fp)=>{
                    let readerx = new FileReader();
                    readerx.onload = ()=>{
                        let bytes = new Uint8Array(readerx.result);
                        let zip = new admzip(Buffer.from(bytes.buffer));
                        var zipEntries = zip.getEntries(); // an array of ZipEntry records
                        let rootdirNum = 0; // 根目录下的文件夹数量
                        let hasPbDir = false; // 是否有pb文件夹
                        let hasNetworkDir = false; // 是否有network文件夹
                        let otherfile = 0; // 除pb和lua之外的文件数量
                        let rootdirName = this.getRootDirName(zipEntries);
                        for (let i=0; i<zipEntries.length; i++) {
                             let isDir = zipEntries[i].isDirectory;
                             let filename = zipEntries[i].entryName;
                             // 根目录下的一级文件夹或文件数量统计(里面只能放一个文件夹如game或config)
                             let num = tool.charnumInsideString(filename, "/");
                             if (0==num || (isDir && 1==num)) {
                                 rootdirNum ++;
                             }
                             if (isDir) {
                                 let pbdir = rootdirName + "pb/";
                                 let networkdir = rootdirName + "network/";
                                 if (pbdir == filename.substr(-pbdir.length)) {// 是否有pb文件夹
                                     hasPbDir = true;
                                 }else if (networkdir == filename.substr(-networkdir.length)) {// 是否有network文件夹
                                     hasNetworkDir = true;
                                 }
                             } else {
                                 if (".pb"!=filename.substr(-3) && ".lua"!=filename.substr(-4)) {
                                     otherfile ++;
                                 }
                             }
                        }
                        console.log("checkresult:", rootdirName, otherfile, rootdirNum, hasPbDir, hasNetworkDir);
                        if (1 != rootdirNum) {
                            this.$message.error("目录结构错误，根目录需要是单个文件夹"); this.dialogUpload=false; this.checkedPkgName=false; this.dialogLoading=false; return;
                        }
                        if (0 != otherfile) {
                            this.$message.error("压缩包中的文件，存在非正常的扩展名"); this.dialogUpload=false; this.checkedPkgName=false; this.dialogLoading=false; return;
                        }                        
                        if (!hasPbDir) {
                            this.$message.error("目录结构错误，缺少pb文件夹"); this.dialogUpload=false; this.checkedPkgName=false; this.dialogLoading=false; return;
                        }
                        if (!hasNetworkDir) {
                            this.$message.error("目录结构错误，缺少network文件夹"); this.dialogUpload=false; this.checkedPkgName=false; this.dialogLoading=false; return;
                        }
                        this.checkExistName(file.name);
                    };
                    readerx.readAsArrayBuffer(fp);
                };
                reader.readAsBinaryString(file.raw);
            },
            onUploadSuccess(response, file) {
                console.log("onUploadSuccess=>", response)
                this.noneUploaded = false;
                this.uploadFileList.push(file)
            },
            onUploadRemove(file) {
                console.log("onUploadRemove=>", file)
                this.checkedPkgName = false;
                this.noneUploaded = true;
                this.uploadFileList = []
            },
			handleSelectionChange(val) {
				this.selectedRows = []
				for (let i = 0; i < val.length; i++) {
					this.selectedRows.push(val[i].id)
				}
				this.noneSelectedRow = 0 == val.length ? true : false
			},

			// 数据表色彩间隔效果     
			tableRowClassName({
				rowIndex
			}) {
				if (0 == rowIndex % 2) {
					return 'warning-row';
				} else {
					return 'success-row';
				}
			},
		},
	}
</script>

<style>
	.serverlist.el-table .warning-row {
		background: #f5f7fa;
	}

	.serverlist.el-table .success-row {
		background: #ffffff;
	}

	.serverlist.el-table {
		margin-top: 16px;
	}

	/*导航菜单字体大小*/
	.serverlist {
		font-size: 12px;
	}
    
    .dlgupload .el-dialog__body{
          display: flex;
          justify-content: center;
          align-items: center;
    }
</style>
